<template>
  <el-row :gutter="10">
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Alongside">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"

          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />

      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Hose Connected">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Commenced Pumping">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Completed Pumping">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Hose Disconnected">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Barge Sailed">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />
      </el-form-item>
    </el-col>
  </el-row>
</template>
<script>
import { DATETIME_FORMAT } from '@/constants.js'

export default {
  name: 'TimeSheetInformation',
  props: {
    jobDataInner: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      DATETIME_FORMAT,

    }
  },


}
</script>

<style lang="scss" scoped>
/deep/.el-input__inner{
  padding-left: 30px;
}
</style>
