<template>
  <div>
    <el-dialog
      style="text-align: left;"
      height="fit-content"
      width="90%"
      title="Select Order"
      :visible="dialogSelectOrder"
      @open="getOrders(50, 0)"
      @close="handleOrderDialogClose"
    >
    <div>
      <el-table :data="orderTableData" v-loading="orderTableLoading" height="48vh" :header-cell-style="{ color: '#909399' }" :cell-style="{padding: '0', height: '80px'}">
        <el-table-column width="55">
          <template v-slot="scope">
            <el-radio :label="scope.row" v-model="orderRadioData"><i/></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="Company" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{myCompanyId === scope.row.sellerSysOrganizationId ? scope.row.buyerCompanyName : scope.row.sellerCompanyName}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Order No" prop="salesOrderNo" show-overflow-tooltip>
          <template v-slot="scope">
            {{ (isSellerView || isCommissionInvoice) ? scope.row.salesOrderNo : scope.row.purchaseOrderNo }}
          </template>
        </el-table-column>
        <el-table-column :label="orderType !== ORDER_TYPE.EX_WHARF ? 'Vessel' : 'Terminal'" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="orderType === ORDER_TYPE.DELIVERED || orderType === ORDER_TYPE.BARGING_NOMINATION">
              {{scope.row.vesselName || '-'}}
            </div>
            <div v-else>
              {{scope.row.terminal || '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Port" show-overflow-tooltip>
          <template v-slot="scope">
            {{scope.row.port || '-'}}
          </template>
        </el-table-column>
        <el-table-column label="Payment Term" prop="paymentTerm" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ capitalizeFirstLetter(scope.row.paymentTerm, '_') }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-bottom: 10px;" class="div-main-list-page-table-pagination" v-if="!orderTableLoading">
        <el-pagination
          background
          @size-change="handleOrderPageSizeChange"
          @current-change="handleOrderPageChange"
          :page-size.sync="orderPagination.pageSize"
          :page-sizes="[5, 10, 20, 50, 100]"
          :current-page="orderPagination.pageNumber"
          layout="total, sizes, prev, pager, next"
          :total="orderPagination.total">
        </el-pagination>
      </div>
      <div class="el-dialog__footer">
        <el-button
          outline
          round

          @click="handleOrderDialogClose"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="!orderRadioData"
          round  type="primary"
          @click="handleConfirmOrderClick"
        >
          Confirm
        </el-button>
      </div>
    </div>
    </el-dialog>
  </div>
</template>
<script>
import { capitalizeFirstLetter } from '@/utils/index.js'
import { ORDER_TYPE, ORDER_STATUS } from '@/constants.js'
import { getOrderById, getOrders } from '@/services/modules/order.js'
export default {
  name: 'DialogSelectOrder',
  props: {
    orderType: String,
    dialogSelectOrder: Boolean,
    orderSelectedId: String,
    isSellerView: Boolean,
    isCommissionInvoice: Boolean
  },
  data () {
    const orderPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      ORDER_TYPE,
      orderTableData: [],
      orderTableLoading: true,
      orderRadioData: null,
      myCompanyId: this.$store.state.currentCompany.id,
      orderPagination
    }
  },
  methods: {
    capitalizeFirstLetter,
    handleOrderPageChange (pageNumber) {
      this.getOrders(this.orderPagination.pageSize, pageNumber - 1)
    },
    handleOrderPageSizeChange (pageSize) {
      this.getOrders(pageSize, 0)
    },
    getOrders (pageSize, pageNumber) {
      this.orderTableLoading = true
      let query = ''
      if (this.isCommissionInvoice) {
        const orderFilterStatus = `sellerOrderStatus: '${ORDER_STATUS.CONFIRMED}' and sellerSysOrganizationId: '${this.myCompanyId}'`
        query = `${orderFilterStatus} and orderType:'${this.orderType}' and exists(commissions.status: 'READY_FOR_INVOICING')&sort=updatedAt desc`
      } else {
        const orderFilterStatus = this.isSellerView ? `sellerOrderStatus: '${ORDER_STATUS.CONFIRMED}' and sellerSysOrganizationId: '${this.myCompanyId}'` : `buyerOrderStatus:'${ORDER_STATUS.CONFIRMED}' and buyerSysOrganizationId: '${this.myCompanyId}' and offlineBuyerSeller: 'SELLER'`
        query = `${orderFilterStatus} and orderType:'${this.orderType}' and exists(products.status: 'PENDING') &sort=updatedAt desc`
      }
      getOrders(pageSize, pageNumber, query).then(res => {
        if (res?.code === 1000) {
          this.orderTableData = res.data.content
          this.orderRadioData = this.orderTableData.find(d => d.id === this.orderSelectedId)
        }
        this.orderPagination.total = res.data.totalElements
        this.orderPagination.pageNumber = res.data.pageable.pageNumber + 1
        this.orderTableLoading = false
      })
    },
    handleOrderDialogClose () {
      this.$emit('handleOrderDialogClose')
    },
    handleConfirmOrderClick () {
      getOrderById(this.orderRadioData.id).then(res => {
        if (res?.code === 1000) {
          this.$emit('handleConfirmOrderClick', res.data)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dialog__footer {
    padding: 10px 10px 0 0;
    height: 100%;
}
</style>
